<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
  >
    <g clip-path="url(#clip0_28_337)">
      <path
        d="M9.34469 10.125L9.86325 6.86742H6.62096V4.75348C6.62096 3.86227 7.07388 2.99355 8.526 2.99355H10V0.220078C10 0.220078 8.66239 0 7.38349 0C4.71337 0 2.96806 1.56023 2.96806 4.38469V6.86742H0V10.125H2.96806V18H6.62096V10.125H9.34469Z"
        fill="white"
      />
    </g>
  </svg>
</template>
