<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
  >
    <path d="M19.5821 2.19042C19.3522 1.32818 18.6743 0.648958 17.8141 0.418542C16.2544 0 10.0002 0 10.0002 0C10.0002 0 3.74599 0 2.18629 0.418906C1.32569 0.649323 0.648197 1.32854 0.418215 2.19078C0 3.75339 0 7.01422 0 7.01422C0 7.01422 0 10.2751 0.417849 11.8377C0.647831 12.6999 1.32569 13.3507 2.18593 13.5811C3.74563 14 9.99982 14 9.99982 14C9.99982 14 16.254 14 17.8137 13.5811C18.6743 13.3507 19.3518 12.6999 19.5818 11.8377C19.9996 10.2747 19.9996 7.01422 19.9996 7.01422C19.9996 7.01422 19.9996 3.75339 19.5818 2.19042H19.5821ZM7.95452 9.97464V4.05344L13.1818 7.01422L7.95452 9.97464Z" fill="white" />
  </svg>
</template>
