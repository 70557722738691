<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#clip0_28_341)">
      <path
        d="M9.00216 4.38515C6.44823 4.38515 4.38822 6.44562 4.38822 9.00012C4.38822 11.5546 6.44823 13.6151 9.00216 13.6151C11.5561 13.6151 13.6161 11.5546 13.6161 9.00012C13.6161 6.44562 11.5561 4.38515 9.00216 4.38515ZM9.00216 12.0005C7.35175 12.0005 6.0025 10.6549 6.0025 9.00012C6.0025 7.34531 7.34773 5.99978 9.00216 5.99978C10.6566 5.99978 12.0018 7.34531 12.0018 9.00012C12.0018 10.6549 10.6526 12.0005 9.00216 12.0005ZM14.8806 4.19637C14.8806 4.79483 14.3988 5.27279 13.8044 5.27279C13.2101 5.27279 12.7283 4.79081 12.7283 4.19637C12.7283 3.60193 13.2101 3.11994 13.8044 3.11994C14.3988 3.11994 14.8806 3.60193 14.8806 4.19637ZM17.9365 5.28886C17.8682 3.84693 17.539 2.56968 16.4829 1.51736C15.4308 0.465029 14.1538 0.135274 12.7122 0.0633782C11.2264 -0.0213702 6.7735 -0.0213702 5.28772 0.0633782C3.85013 0.131659 2.57316 0.461013 1.51706 1.51334C0.460949 2.56567 0.135283 3.84292 0.0630018 5.28444C-0.0213261 6.77055 -0.0213261 11.2249 0.0630018 12.711C0.131267 14.1529 0.460548 15.4302 1.51665 16.4825C2.57276 17.5348 3.84571 17.8642 5.28732 17.9365C6.7731 18.0208 11.2264 18.0208 12.7122 17.9365C14.1538 17.8682 15.4308 17.5388 16.4829 16.4825C17.5349 15.4302 17.8642 14.1529 17.9365 12.711C18.0208 11.2249 18.0208 6.77457 17.9365 5.28846V5.28886ZM16.017 14.3059C15.7038 15.0932 15.0975 15.6997 14.3064 16.017C13.1218 16.4869 10.3109 16.3785 9.00176 16.3785C7.69267 16.3785 4.87773 16.4829 3.69713 16.017C2.91007 15.7037 2.30372 15.0972 1.98648 14.3059C1.51665 13.1211 1.62508 10.3095 1.62508 9.00012C1.62508 7.69074 1.52067 4.87516 1.98648 3.69431C2.2997 2.90707 2.90606 2.30058 3.69713 1.98327C4.88174 1.51334 7.69267 1.62178 9.00176 1.62178C10.3109 1.62178 13.1258 1.51736 14.3064 1.98327C15.0935 2.29656 15.6998 2.90305 16.017 3.69431C16.4869 4.87918 16.3785 7.69074 16.3785 9.00012C16.3785 10.3095 16.4869 13.1251 16.017 14.3059Z"
        fill="white"
      />
    </g>

  </svg>
</template>
